
.cast-list {
  list-style: none;
  padding: 0;
}


.cast-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}
.cast-photo {
  width: 70px;
  height: 105px; 
  border-radius: 10px;
  margin-right: 10px;
}

.cast-info strong {
  display: block;
}

.cast-info p {
  font-size: 1em;
  color: gray;
}
.button-link {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s;
}

.button-link:hover {
  background-color: #0056b3;
  cursor: pointer;
}
.cast-details-container {
  display: flex;
  background-color: #191C24;
  padding: 20px;
  border-radius: 10px;
  color: white;
  align-items: start;
  gap: 20px;
}
.cast-images {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cast-profile-image {
  width: 150px;
  height: 225px;
  border-radius: 10px;
  margin-bottom: 10px;
}


.cast-poster-image {
  width: 100px;
  height: 150px;
  border-radius: 10px;
}


.cast-details-card {
  display: flex;
  align-items: center;
  background-color: #2C2F33;
  padding: 20px;
  border-radius: 10px;
}

.cast-details-image {
  width: 150px;
  height: 225px;
  border-radius: 10px;
  margin-right: 20px;
}

.cast-details-info {
  flex: 1;
}

.cast-details-title {
  font-size: 24px;
  font-weight: bold;
}

.cast-details-text {
  font-size: 16px;
  color: lightgray;
}

.media-title {
  font-size: 20px;
  margin-top: 10px;
}

.media-overview {
  font-size: 14px;
  margin-top: 5px;
}


.cast-details-info {
  flex-grow: 1;
  padding: 10px;
}

.cast-details-section {
  margin-top: 10px;
}

.cast-detail {
  font-size: 16px;
  color: lightgray;
  margin: 5px 0;
}

.cast-detail-title {
  font-weight: bold;
  color: #FFFFFF;
}

.button-link {
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  padding: 10px 20px;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  transition: background-color 0.3s;
}

.button-link:hover {
  background-color: #0056b3;
  cursor: pointer;
}
