.watchlist-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  /* Adjust the gap as needed */
  justify-content: flex-start;
  /* Aligns items to the start of the container */
  align-items: stretch;
  /* Stretch items to fill the container height */
}

.watchlist-page {
  bottom: 10px;
  left: 10px;
  background: #191C24;
  border-radius: 5px;

}


.watchlist-header {
  width: 100%;
  /* Ensures the header takes full width */
  padding-left: 10px;
  /* Aligns with the items if they have padding */
}

.watchlist-item:hover {
  transform: scale(1.03);
  /* Optional: Slight zoom on hover */
  display: block;
  /* Show on hover */
}

.watchlist-item .delete-item-btn {
  display: none;
  /* Hide the delete button by default */
}

.watchlist-item:hover .delete-item-btn {
  display: block;
  /* Show the delete button when the item is hovered */
}

.delete-item-btn {
  position: absolute;
  top: 0px;
  /* Adjust as needed */
  left: 5px;
  /* Adjust as needed */
  color: red;
  border: none;
  /* No border */
  cursor: pointer;
  font-size: 30px;
  padding: 4px 8px;
  /* Adjust as needed */
  display: none;
  /* Hide by default */
}

.undo-delete-btn {
  display: none;
  /* Initially hidden */
  position: absolute;
  bottom: 12px;
  /* Adjust as needed */
  right: 12px;
  /* Adjust as needed */
  /* Other styles as per your design */
}

/* Show the undo button if available */
.watchlist-item.undo-available .undo-delete-btn {
  display: block;
}

.hidden-text {
  display: none;
  /* Initially hidden */
  position: absolute;
  bottom: 8px;
  /* Adjust as needed */
  left: 8px;
  /* Adjust as needed */
  /* Other styles as per your design */
}

/* Show the hidden text if available */
.watchlist-item.undo-available .hidden-text {
  display: block;
}


.fade-out {
  animation: fadeOut 0.5s forwards;
  /* 0.5s duration and use the forwards fill-mode */
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    height: 0;
    padding: 0;
    margin: 0;
  }
}

.watchlist-item.undo-available img {
  filter: grayscale(100%);
  /* Convert image to black and white */
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  background-color: #007bff;
}

.sorting-btn-group {
  display: flex;
  justify-content: left;
}

.sort-btn {
  background-color: #333;
  color: #fff;
  border: 1px solid #444;
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.sort-btn.active,
.sort-btn:hover {
  background-color: #007bff;
}

.move-to-first-icon {
  display: none;
  /* Hide by default */
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: #fff;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
  border-radius: 5px;
}

.watchlist-item:hover .move-to-first-icon {
  display: block;
  /* Show on hover */
}

.move-to-last-icon {
  display: none;
  /* Hide by default */
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #fff;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
  border-radius: 5px;
}

.watchlist-item:hover .move-to-last-icon {
  display: block;
  /* Show on hover */
}

/* Add styles for new section containers */
.watch-soon-container,
.watch-later-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}


.watchlist-item-overlay {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent overlay */
  color: white;
  padding: 10px;
  border-radius: 5px;
}

.watchlist-item:hover .watchlist-item-overlay {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.rating-badge,
.move-icons,
.delete-item-btn {
  display: none;
  /* Hide these elements by default */
}

.watchlist-item:hover .rating-badge,
.watchlist-item:hover .move-icons,
.watchlist-item:hover .delete-item-btn {
  display: block;
  /* Show on hover */
}

/* Show the undo button if the item is marked as undo-available */
.watchlist-item.undo-available .undo-delete-btn {
  display: block;
  /* Make the undo button visible */
  position: absolute;
  bottom: 12px;
  /* Adjust as needed */
  right: 12px;
  /* Adjust as needed */
  z-index: 10;
  /* Ensure it's above other elements */
}

/* Watchlist.css */
.details-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  /* Make sure this is above everything else */
}

.details-overlay-content {
  background-color: dimgray;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 101;
  /* Above the overlay background */
  position: relative;
}

.media-type-selector {
  /* Styles for the container holding the clickable text */
  display: flex;
  gap: 10px;
}

.media-type-option {
  /* Base styles for each clickable option */
  cursor: pointer;
  color: gray;
  /* Default color */
  padding-bottom: 5px;
  /* Space for the underline */
}

.media-type-option:hover {
  /* Hover effect */
  color: #fff;
}

.media-type-option.active {
  /* Styles for the active/selected option */
  color: #fff;
  /* White text */  
}


.move-to-start,
.move-to-end {
  position: absolute;
  cursor: pointer;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
  border-radius: 5px;
}

.move-to-start {
  left: 5px;
  font-size: 16px;
  bottom: 14px;
}

.move-to-end {
  right: 5px;
  font-size: 16px;
  bottom: 14px;

}

.top-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
}

.sort-option {
  cursor: pointer;
  color: gray;
  /* Default color */
  padding-bottom: 5px;
  /* Space for the underline */
}

.sort-option:hover {
  color: #fff;
}

.sort-option.active {
  color: #fff;
  /* White text for active option */
  border-bottom: 2px solid #fff;
  /* Underline effect */
}


.search-bar-container {
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  /* Ensures padding doesn't add to the width */
}

.ai-search-field {
  width: 100%;
  padding: 10px;
  border: 0px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  /* Prevents padding from increasing the total width */
}


.search-bar {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 95%;
  margin-top: 20px;
}

.watch-scroll-container {
  display: flex;
  /* Keep using flexbox */
  flex-wrap: nowrap;
  /* Prevent items from wrapping */
  overflow-x: auto;
  /* Allow horizontal scrolling */
  gap: 10px;
  /* Space between items */
  padding: 10px 0;
  /* Vertical padding without horizontal padding */
}


.watchlist-item-list {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.watchlist-item-info h4 {
  margin: 0;
  padding-bottom: 10px;
}

.watchlist-item-info .play-button {
  display: inline-block;
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  text-decoration: none;
  border-radius: 5px;
  margin-right: 10px;
}

.watchlist-item-info p {
  margin-top: 10px;
}



.media-left {
  flex: 0 0 auto;
  /* Do not grow or shrink */
  margin-right: 20px;
  /* Add space between the media-left and media-right */
}

.media-right {
  flex: 1;
  /* Allow to grow to fill the space */
  /* Add styling as needed */
}

.provider-icons-container {
  display: flex;
  flex-wrap: wrap;
  /* Add additional styling as needed */
}

/* Ensure the overlay takes up the size of the media-left */
.watchlist-item-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* rest of your styling */
}

.episode-input {
  width: 45px;
  padding: 3px;
  margin: 0 5px;
  font-size: 14px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
}

.watchlist-item {
  transition: filter 0.5s ease, opacity 0.3s ease;
  /* Smooth transitions for visual effects */
  cursor: pointer;
  /* Indicates the item is clickable */
  margin: 10px;
  /* Margin right is okay */
  gap: 10px;
  flex: 0 0 auto;
  /* Prevent flex items from shrinking smaller than their content */
}

.watchlist-item img {
  width: 180px;
  border-radius: 5px;
}

@media (max-width: 768px) {


  .watchlist-item {
    transition: filter 0.5s ease, opacity 0.3s ease;
    /* Smooth transitions for visual effects */
    cursor: pointer;
    /* Indicates the item is clickable */
    margin: 10px;
    /* Margin right is okay */
    gap: 10px;
    flex: 0 0 auto;
    /* Prevent flex items from shrinking smaller than their content */
    width: 60%;

  }

  .media-list-image {
    display: none;

  }

  .watchlist-item img {
    border-radius: 5px;
    width: 100%;
  }
}


.grayscale {
  filter: grayscale(60%);
}

.selected-item {
  filter: none;
  /* Ensure the selected item has no grayscale filter */
  opacity: 1;

}



.provider-icon {
  width: 50px;
  height: 50px;
  margin-right: 5px;
}

.provider-small-icon {
  width: 35px;
  /* Smaller size */
  height: 35px;
  /* Maintain aspect ratio */
  border-radius: 1px;
  margin-left: 5px;
  /* Space between icons */
  margin-bottom: 5px;
}

.scroll-arrow-wl {
  position: absolute;
  top: 50%;
  z-index: 100;
  cursor: pointer;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 10px;
  transform: translateY(-50%);
}

.scroll-arrow-wl.left {
  left: 40px;
  top: 340px;
  font-size: 2.5rem;
}

.scroll-arrow-wl.right {
  right: 40px;
  top: 340px;
  font-size: 2.5rem;
}


/* CSS for the Watchlist component */
.watchlist-page {
  background-color: #191C24;
  border-radius: 10px;
  margin-top: 15px;
}

.loading-container {
  text-align: center;
  margin-top: 20px;
  color: white;
}

.loading-spinner {
  font-size: 24px;
  color: white;
}

.watchlist-container {
  margin-top: 15px;
}

.provider-icon {
  max-width: 30px;
  max-height: 30px;
  margin-right: 5px;
}

.provider-spinner {
  position: absolute;
  bottom: 15px;
  right: 15px;
  color: white;
}

.bookmark-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.watchlist-item-name-editing {
  font-family: 'Times New Roman';
  font-size: 24px;
  color: black;
  width: 100%;
}

.generate-name-btn {
  font-size: 16px;
  display: block;
  cursor: pointer;
}

.empty-watchlist-message {
  text-align: center;
  color: white;
}