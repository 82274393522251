.search-input {
    width: 200px;
    position: absolute;
    right: 150px;
    padding: 10px 20px;
    border-radius: 20px;
    border: 1px solid gray;
    background-color: #1f2028;
    font-size: 14px;
    color: white;
    outline: none;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
    color: white;
}