.media-details-container {
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.media-title {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.content-layout {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.media-poster {
  flex-shrink: 0;
  margin-right: 20px;
  border-radius: 5px;
  width: 350px; /* Adjust as needed */
  height: auto;
}

.right-side-content { 
  flex-direction: column;
  align-items: flex-start; /* Align items to the start, removing centering */
  width: 100%;
}

.media-overview, .additional-videos iframe {
  width: 100%; /* Ensure full width usage for right-side content */
  margin-top: 20px;
  font-size: 20px;
  text-align: left; /* Align text to the left */
}

  /* Watch Now Button */
  .watch-now-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #007bff;
    color: white;
    padding: 12px 30px;
    border-radius: 15px;
    margin: 10px;
    text-decoration: none;
    transition: background-color 0.2s;
  }



  .watch-and-providers {
    display: flex;
    align-items: center;
    margin-top: 5px; /* Adjust spacing as needed */
  }
  
  .provider-icons-container {
    display: flex;
    margin-left: 10px; /* Adjust spacing as needed */
  }
  
  
  .media-poster-compact {
    width: 175px; /* Smaller size */
    height: auto;
    display: none;
  }
  
  .media-overview-compact {
    font-size: 16px; /* Smaller text */
    display: none;
  }
  
  .provider-icon-compact {
    width: 30px; /* Smaller size */
    height: 30px; /* Maintain aspect ratio */
  }
  .provider-icon {
    width: 50px; /* Smaller size */
    height: 50px; /* Maintain aspect ratio */
    border-radius: 5px;
  }
  
  .card {
    background-color: #282c34;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    overflow: hidden;
    padding: 20px;
    width: calc(100% - 40px); /* Adjust width based on padding */
  }
  
  .card img {
    width: 100%;
    height: auto; 
  }
  
  .card-content {
    padding: 15px;
  }
  
  .card-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .card-subtitle {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .card-text {
    font-size: 16px;
    line-height: 1.6; /* Adjust line height for readability */
    color: #333; /* Darker text color for better readability */
  }
  

  @media (max-width: 768px) {
    .provider-icon {
      width: 150px; /* Smaller size */
      height: 150px; /* Maintain aspect ratio */
      border-radius: 5px;
    }
  

  }

  .glossary-section {
    padding: 20px;
    background-color: gray;
    border-radius: 10px;
    margin-top: 20px;
  }
  
  .glossary-entry {
    margin-bottom: 10px;
    color: lightgray;
  }
  