  
  .questions-container {
    display: flex;
    justify-content: space-between; /* Space out the two columns */
    margin-top: 20px;
  }
  
  .mbti-questions, .alignment-questions {
    flex: 1; /* Each takes up half the width */
    padding: 20px;
    background-color: #2a2d3d; /* Slightly lighter than container for contrast */
    margin: 0 10px; /* Space between the two columns */
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Subtle shadow for depth */
  }
  
  .option-button {
    display: block; /* Make buttons block-level for full width */
    margin: 10px 0; /* Vertical spacing */
    padding: 10px;
    background-color: #4a90e2; /* Vibrant blue */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .option-button:hover {
    background-color: #357ab8; /* Darker blue on hover */
  }
  
  .back-link {
    display: inline-block;
    color: #4a90e2; /* Same blue as buttons for consistency */
    text-decoration: none;
    margin-top: 20px;
  }
  
  .back-link:hover {
    text-decoration: underline; /* Underline on hover for clarity */
  }
  
  .questions-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .mbti-questions,
  .alignment-questions {
    padding: 1rem;
    background-color: #282c34;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .question-block {
    margin-bottom: 1rem;
  }
  
  .question {
    margin-bottom: 0.5rem;
  }
  
  .option-button {
    width: 100%;
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;
    border: none;
    background-color: #61dafb;
    color: #282c34;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .option-button:hover {
    background-color: #4fa8d7;
    transform: translateY(-2px);
  }
  
  .option-button:disabled,
  .option-button.selected {
    background-color: #4fa8d7;
    cursor: default;
    transform: none;
  }
  
  .back-link {
    display: block;
    margin-top: 1rem;
    color: #61dafb;
    text-decoration: none;
    font-weight: bold;
  }
  
  .back-link:hover {
    text-decoration: underline;
  }
  .mbti-input {
  width: 3rem; /* Smaller width for single-character MBTI input */
  font-size: 1rem; /* Larger font size for clear legibility */
  margin: 0.25rem; /* Consistent spacing around elements */
  padding: 0.5rem; /* Ample padding for a square appearance */
  text-align: center; /* Center text for a neat look */
  border-radius: 5px; /* Rounded corners for inputs and selects */
  border: 1px solid gray; /* Border color to match the theme */
  background-color: #282c34; /* Background color to match the theme */
  color: #61dafb; /* Font color to match the theme */
}


/* PersonaMatch.css */
.persona-match-container {
  flex-direction: column;
  align-items: center;
  background-color: #1f2028;
  color: #fff;
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
}

.layout-sections {
  display: flex;
  justify-content: left;
}

.alignment-section {
  flex: 1;
  margin: 0 10px;
}
.mbti-section {
  flex: 1;
  margin: 0 10px;
}

.mbti-inputs {
  display: flex;
  justify-content: left;
  margin-bottom: 10px;
}

.mbti-help { 
  justify-content: center;
  align-items: center;
  margin: 10px;

}

.mbti-not-selected {
  padding: 5px 10px;
  background-color: gray;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: #282c34;
  margin-top: 10px;
  outline: none;
  font-size: 16px;
}

.mbti-selected {
  color: "#61dafb";
  padding: 5px 10px;
  background-color: #282c34;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: #4a90e2;
  
  margin-top: 10px;
  outline: none;
  font-size: 16px;
}

.help-button {
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
  outline: none;
}

.mbti-question {
  margin-bottom: 20px;
  font-size: 16px;
}

.cast-grid-persona {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}
.question-section {
  background-color: #1f2028;   
  border-radius: 5px;
}

.cast-member {
  background-color: #282c34;
  padding-left: 1rem;
  border-radius: 5px;
  display: flex;
}

.cast-profile {
  width: 100%;
  height: auto;
  border-radius: 5px;
}
.cast-grid-wide {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(800px, 1fr));
  gap: 20px;
}
.cursor-pointer {
  cursor: pointer;
}
/* CSS for normal image state */
.actor-info img {
  transition: filter 0.3s ease; /* Smooth transition for the filter effect */
  cursor: pointer; /* Ensures the cursor changes to a pointer */
}

/* CSS for hover state */
.actor-info img:hover {
  opacity: .6;
  filter: grayscale(100%); /* Converts the image to grayscale on hover */
}
