.media-item img {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.media-item:hover img {
  transform: scale(1.05);
}

.media-title {
  padding: 0.5rem;
  text-align: center;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  width: 100%;
}

.overlay,
.hide-icon i,
.undo-hide,
.hidden-text {
  transition: opacity 0.3s ease;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.media-item:hover .overlay {
  opacity: 1;
}

.hide-icon,
.undo-hide {
  position: absolute;
  background: none;
  border: none;
  color: red;
  cursor: pointer;
}

.hide-icon {
  top: 1px;
  left: 1px;
}

.undo-hide {
  bottom: 10px;
  left: 10px;
  background: rgba(255, 255, 255, 0.7);
  padding: 5px 10px;
  border-radius: 5px;
  display: none;
}

.media-item:hover .undo-hide {
  display: block;
}

.media-item.hidden img {
  filter: grayscale(100%);
  opacity: 0.5;
}

.hidden-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  display: none;
}

.media-item:hover .hidden-text {
  display: block;
}

.fade-out {
  animation: fadeOut 0.5s forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    height: 0;
    padding: 0;
    margin: 0;
  }
}

.react-tabs__tab--selected {
  border-bottom: 2px solid white;
}

.react-tabs__tab:not(.react-tabs__tab--selected) {
  border-bottom: none;
}


.media-item {
  flex: 0 0 auto;
  /* Do not grow, do not shrink, base width on content */
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  cursor: pointer; 
  display: inline-block;
  position: relative;
  width: 200px;
  margin-right: 10px;

}
@media (max-width: 768px) {
 

  .media-item {
    /* Do not grow, do not shrink, base width on content */
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    margin-right: 1rem;
    position: relative;
    width: 60%;
    margin-right: 10px;

  }

  .media-title {
    font-size: 0.8rem;
  }

  .hide-icon i,
  .undo-hide,
  .hidden-text {
    font-size: 0.8rem;
  }
}


/* MediaGrid.css */
.media-grid {
  display: flex;
  overflow-x: auto;
  gap: 1rem;
  padding: 1rem;
  background-color: #191C24;
  position: relative;
  white-space: nowrap;
}


.selected-item {
  transition: transform 500ms ease-in-out;
}

.animate-move-left {
  transition: transform 500ms ease-in-out;
  transform: translateX(-100%);
  /* Ensure this moves the item all the way to the start */
}

.media-item:first-child {
  transition: none;
}

.media-item.animate-move-left {
  transition: transform 500ms ease-in-out;
  transform: translateX(-150%);
  /* Adjust this value based on your layout */
}

.bw-effect img {
  filter: grayscale(20%);
  opacity: 1;
  /* Make image black and white */
}

.selected-effect img {
  filter: grayscale(0%);
  border: 1px solid #333;
  border-radius: 3px;
  /* Make image black and white */
}



.scroll-arrow {
  position: absolute;
  top: 440px;
  z-index: 100;
  cursor: pointer;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 10px;
  transform: translateY(-50%);
}

.scroll-arrow.left {
  left: 30px;
  font-size: 2.5rem;
}

.scroll-arrow.right {
  right: 30px;
  font-size: 2.5rem;
}

.hide {
  display: none;
}

/* Media Grid container */
.media-grid-container {
  margin-top: 10px;
  width: 100%;
  position: relative;
}
