/* HomePage.css */
.home-page-container {
  background-color: #000;
  color: white;
  padding-bottom: 2rem;
}

.search-bar-container {
  padding: 1rem;
  text-align: center;
}

.search-input {
  width: 100%;
  max-width: 600px;
  padding: 0.5rem;
  border-radius: 5px;
  margin-bottom: 1rem;
}

.tab-container {
  display: flex;
  justify-content: left;
  padding-bottom: 1rem;
}

.tab {
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  transition: border-color 0.3s ease;
}

.tab:hover {
  border-color: white;
}

.tab.active {
  border-color: white;
}

@media (max-width: 768px) {
  .tab-container {
    overflow-x: auto;
    justify-content: flex-start;
  }
  
  .tab {
    flex: none;
  }
}
.react-tabs__tab--selected {
  border-bottom: none; /* Remove the bottom border */
  outline: none; /* Remove focus outline */
}

/* Optional: to apply a custom focus style that's less intrusive */
.react-tabs__tab:focus {
  outline: thin dotted; /* Example focus style */
}

.react-tabs__tab:focus::after {
  content: '';
  display: block;
  position: absolute;
  bottom: -1px; /* Align to the bottom of the tab */
  left: 0;
  right: 0;
  height: 2px; /* Height of the underline */
  background-color: currentColor; /* Use the text color for the underline */
}

/* HomePage.css */
.react-tabs {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.react-tabs__tab-list {
  border-bottom: 2px solid #ccc;
  margin: 0 0 10px;
  padding: 0;
  display: flex;
  justify-content: left;
}

.react-tabs__tab {
  border: none;
  border-bottom: 3px solid transparent;
  padding: 10px 15px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.react-tabs__tab--selected {
  border-bottom: 3px solid #007bff;
  color: #007bff;
}

.react-tabs__tab:focus {
  box-shadow: none;
  border-color: #007bff;
}

.react-tabs__tab-panel {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Adjustments for other elements */
.SearchBar input[type="text"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box; /* Prevents padding from increasing the total width */
}

.link-button {
  background: none;
  border: none;
  color: white; /* Your link color */
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
}

.link-button:hover {
  color: gray; /* Hover state color */
}
.link-button {
  background: none;
  border: none;
  text-decoration: none;
  color: gray; /* Your link color */
  cursor: pointer;
  padding: 0;
  font-weight: normal; /* Default weight */
}

.link-button.selected {
  text-decoration: underline; /* Remove underline */
  color: white; /* Your link color */
}

.link-button:hover {
  color: gray; /* Hover state color */
  text-decoration: underline; /* Ensure no underline on hover */
}
@media (max-width: 768px) {
  .link-button {
    font-size: 0.9rem; /* Slightly smaller on mobile */
  }
}


/* Adjust the search bar and tab container for mobile devices */
@media (max-width: 768px) {
  .search-bar-container {
    padding: 0.5rem; /* Reduce padding on smaller screens */
  }

  .search-input {
    padding: 10px; /* Increase padding for the input */
    font-size: 16px; /* Increase font size for better visibility */
  }

  .tab-container {
    font-size: smaller; /* Reduce the font size of the tabs */
    padding: 0.5rem; /* Reduce the overall padding */
  }

  .tab {
    padding: 8px; /* Adjust padding for each tab to fit more content */
  }
}


/* Ensure the grid adapts to smaller screens */
@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Adapt columns for mobile */
    gap: 10px; /* Reduce gap size */
    padding: 10px; /* Reduce overall padding */
  }

  .grid-item {
    margin-right: 0; /* Remove the margin to allow full-width items */
  }
}
 
.active-tab {
  cursor: pointer;
  color: #fff;  /* White color for active tab */
}

.inactive-tab {
  cursor: pointer;
  color: gray;  /* Lighter color for inactive tab */
}
.instructions {
  text-align: left; 
}
