body,
html,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  background-color: #000;
  /* This sets the background color to black */
  color: white;
  /* This sets the text color to white */
}


.recommendation-section {
  position: fixed;
  /* Fixed position */
  bottom: 0;
  /* Stick to the bottom */
  left: 0;
  /* Stick to the left */
  width: 100%;
  /* Full width */
  background-color: #191C24;
  /* Background color */
  color: white;
  /* Text color */
  opacity: .9;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3);
  /* Box shadow for a "lifting" effect */
  z-index: 1000;
  /* Ensure it's above other content */
  display: none;
  /* Hidden by default */
}

.close-btn {
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
}


.recommendation-content {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-top: 10px;
}

.recommendation-img {
  max-width: 75px;
  border-radius: 5px;
}

.recommendation-info {
  flex-grow: 1;
}

.recommendation-info p {
  margin-bottom: 10px;
}



.image-container {
  position: relative;
  cursor: pointer;
}

.image-container:hover .overlay,
.image-container:hover .add-watchlist-btn,
.image-container:hover .hide-icon {
  display: block;
}

.overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  /* Center children horizontally */
  align-items: center;
  /* Center children vertically */
}


#search-results-div {
  overflow-x: auto;
  /* Scroll horizontally if overflow */
  overflow-y: hidden;
  white-space: nowrap;
  height: auto;
  gap: 20px;
  /* This sets the gap between grid items */
  padding: 20px;
  /* This adds some padding around the grid */
}

.search-result-item {
  float: left;
  width: 200px;
  /* Width of each item */
  margin-right: 10px;
  /* Margin to the right of each item */
  background-color: #191C24;
  /* Background color for each item */
  color: #fff;
  /* Text color */
  border-radius: 10px;
  /* Rounded corners */
  position: relative;
  /* Allows absolute positioning of children */
}

/* Image styling */
.search-result-img {
  width: 100%;
  /* Image to cover the width of its container */
  border-radius: 10px;
}

.search-result-item h3 {
  padding: 10px;
  margin: 0;
  font-size: 1em;
  text-align: center;
}

.search-result-item p {
  padding: 0 10px;
  font-size: 0.8em;
}

.search-result-buttons {
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
  padding-bottom: 10px;
}

.search-add-btn,
.search-details-btn {
  flex: 1;
  /* Buttons take equal width */
  padding: 5px 10px;
  /* Padding inside the buttons */
  margin: 0 5px;
  /* Margin around the buttons */
  border: none;
  /* No border for buttons */
  background-color: #007bff;
  /* Button background color */
  color: white;
  /* Button text color */
  cursor: pointer;
  /* Cursor to indicate it's clickable */
  border-radius: 5px;
  /* Rounded corners for the buttons */
}





.undo-hide-img {
  max-width: 100%;
  /* Set a max-width that fits the grid item size */
  max-height: none;
  /* Allow the image to define its height automatically */
  width: auto;
  /* Maintain aspect ratio */
  height: auto;
  /* Maintain aspect ratio */
  display: block;
  /* This will prevent inline default space */
}


.undo-timer {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #c66900;
  /* Darker shade of the button color for contrast */
  transition: width 1s linear;
}



.undo-hide-button {
  display: inline-block;
  /* Change from flex to inline-block */
  position: relative;
  background-color: #dcb477;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  width: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 200px;
  /* Same as the grid-item width */
  max-height: 320px;
  /* Same or less than the grid-item height */
  box-sizing: border-box;
  /* Include padding and borders in the width and height */
}

.undo-hide-button img {
  position: absolute;
  top: 0;
  left: 0;
  opacity: .2;
  border-radius: 2px;
  width: 100%;
  /* Ensure the image fills the button */
  height: auto;
  /* Maintain aspect ratio */
}

.undo-hide-button span {
  z-index: 1;
  font-weight: bold;
}

.undo-timer {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #c66900;
  height: 10px;
  width: 100%;
  transition: width 1s linear;
}



.navbar {
  justify-content: left;
}

.nav-link {
  top: 2px;
  position: relative;
}

/* Custom CSS for changing tab color to white when selected */
.nav-tabs .nav-link.active {
  color: white;
  /* Change text color to black */
  background-color: #000;
}

.grid-container {
  flex-wrap: nowrap;
  /* This prevents the items from wrapping to the next line */
  display: grid;
  /* Change from flex to grid */
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  /* Adjust as needed */
  grid-gap: 20px;
  /* Space between items */
  padding: 20px;
  background-color: #191C24;
  /* Your desired background color */
  overflow: hidden;
  /* Prevent horizontal scrolling */
}

.grid-item {
  /* Optional border */
  border-radius: 10px;
  /* Rounded corners */
  overflow: hidden;
  /* Ensures the content fits within the corners */
  position: relative;
  /* Needed for absolute positioning of children */
  flex: 0 0 auto;
  /* Do not grow or shrink */
  width: 200px;
  /* Width of each item */
  margin-right: 10px;
  /* Spacing between items */
}

.grid-item img {
  width: 100%;
  /* Ensures the image fills the container */
  height: auto;
  /* Keeps the aspect ratio of the image */
}

.grid-item .overlay {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  /* Aligns button to the right */
  align-items: flex-start;
  /* Aligns button to the top */
  padding: 10px;
  /* Padding for the top right */
}

.grid-item:hover .overlay {
  display: flex;
}

.add-watchlist-btn { 
  position: absolute;
  top: 10px;
  right: 0px;
}
.delete-icon-btn { 
  position: absolute;
  top: 0px;
  /* Adjust as needed */
  left: 5px;
  /* Adjust as needed */
  color: red;
  border: none;
  /* No border */
  cursor: pointer;
  font-size: 30px;
  padding: 4px 8px; 
  /* Hide by default */
}

.hide-icon {
  display: none;
  position: absolute;
  top: 10px;
  left: 0px;
}

.delete-reco-icon {
  display: inherit;
  position: absolute;
  background: none;
  border: none;
  top: 10px;
  left: 5px;
  font-size: 32px;
  color: red;
}


.add-watchlist-btn i {
  font-size: 24px;
  /* Adjust icon size as needed */
}

.play-icon-btn {
  font-size: 73px;
  /* Adjust icon size as needed */
  color: white;
  /* Adjust icon color as needed */
  position: absolute;
  right: 72px;
  bottom: 110px;
  background: none;
  border: none;
  color: white;
  /* Adjust button color as needed */
  cursor: pointer;
}


.add-icon {
  border: none;
  /* No border for buttons */
  position: absolute;
  top: 10px;
  /* Adjust as needed for your design */
  right: 10px;
  /* Adjust as needed for your design */
  display: block;
  /* Hide the plus icon by default */
  cursor: pointer;
  /* Ensures the icon indicates it's clickable */
  position: absolute;
  /* You can adjust as needed based on your layout */
  top: 10px;
  right: 10px;
}

/* Additional styles for the icon, if needed */
.add-icon i {
  font-size: 1.5em;
  /* Example size, adjust as needed */
}

.load-more-btn {
  flex: 0 0 auto;
  /* Prevent the button from growing or shrinking */
  margin: 0 20px;
  /* Adjust spacing as needed */
  white-space: nowrap;
  /* Ensure the text doesn't wrap */
  padding: 10px 20px;
  /* Adjust padding for aesthetics */
  /* Additional styling */
}


.grid-item img {
  width: 100%;
  /* Ensures the image fills the container */
  height: auto;
  /* Keeps the aspect ratio of the image */
}

.hide-icon {
  font-size: 24px;
  /* Adjust size accordingly */
  cursor: pointer;
  /* Add this line to change the cursor */
  background: none;
  border: none;
  position: absolute;
  top: 10px;
  left: 10px;
  /* Positioned at the top left */
  color: lightgray;
  z-index: 5;
}

.hide-icon,
.add-watchlist-btn,
.undo-hide {
  background: none;
  border: none;
  color: white;
  /* Adjust color as needed */
  cursor: pointer;
} 

.hide-icon i,
.add-watchlist-btn i,
.undo-hide {
  font-size: 24px;
  /* Adjust size as needed */
}

/* Media query for devices with a max-width of 600px */
@media (max-width: 600px) {
  .play-icon-btn {
    right: 50%;
    /* Center horizontally */
    bottom: 50%;
    /* Center vertically */
    transform: translate(50%, 50%);
    /* Adjust for icon's dimensions */
  }
}


@media (min-width: 1200px) .container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  max-width: 1440px;
  background-color: #191C24;
  /* Your desired background color */
}




#root {
  width: 100vw;
}

/* Full-width container */
.container-fluid {
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

/* For extra large screens, adjust the max-width to your preference */
@media (min-width: 1400px) {

  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
    /* or any other value that suits your design */
  }
}

/* Reducing padding around main content if it's too much */
.main-content {
  padding: 10px;
  /* Reduce this value if there's too much padding */
}

/* Ensure that grid containers take all available width */
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
  width: calc(100% - 40px);
  /* Subtracting total horizontal padding */
  margin: auto;
  /* Center the grid */
}

/* Adjusting the grid item width might allow more items to fit horizontally */
.grid-item {
  flex: 1 0 auto;
  /* Allow items to grow to fill space */
  width: auto;
  /* Auto width for flexibility */
  margin-right: 10px;
  /* Adjust margin as needed */
}

/* Ensure your images are responsive and do not overflow their containers */
img {
  max-width: 100%;
  height: auto;
}
.mobile-hide {
  display: block;
  /* Hide elements with this class on mobile */
}
.mobile-show {
  display: none;
  /* Hide elements with this class on mobile */
}

@media (max-width: 870px) {
  .mobile-hide {
    display: none;
    /* Hide elements with this class on mobile */
  }
  .mobile-show {
    display: block;
    /* Hide elements with this class on mobile */
  }

  .search-bar-container .search-input {
    font-size: 18px;
    /* Make the font larger */
    padding: 15px;
    /* Larger padding for easier interaction */
  }

  .grid-item {
    width: calc(50% - 20px);
    /* Adjust the grid item width to take half of the screen width minus some margin */
    margin: 10px;
    /* Adjust margin for spacing */
  }

  .grid-container {
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    /* Make the grid responsive */
  }

  .image-container img {
    width: 100%;
    /* Ensure images are responsive */
    height: auto;
    /* Maintain aspect ratio */
  }

  /* Adjust the font size and padding of elements within .recommendation-section for better readability */
  .recommendation-section .recommendation-content,
  .recommendation-info p,
  .close-btn {
    font-size: larger;
    /* Increase the font size */
    padding: larger;
    /* Increase padding for touch targets */
  }
}

.footer-container {
  display: flex;
  /* Use flexbox for layout */
  justify-content: center;
  /* Center children horizontally */
  align-items: center;
  /* Center children vertically (if needed) */
  text-align: center;
  /* Center text */
}

.navbar {
  display: flex;
  justify-content: space-between;
  /* Align items on both ends */
  align-items: center;
  padding: 0.5rem 1rem;
}

.nav-left,
.nav-right {
  display: flex;
  align-items: center;
}

.nav-link {
  padding: 0.5rem 0.75rem;
  margin: 0 0.25rem;
  /* Spacing between links */
  display: flex;
  align-items: center;
}

/* Active state for nav links */
.nav-link.active {
  color: white;
  /* White text for active link */
}

/* Add to your App.css */
.fixed-top-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1030;
  /* Ensure it's above other content */
}

/* Adjust the padding of the body or the next content element to avoid overlap */
.body-content {
  padding-top: 0px;
  /* Adjust based on the actual height of your navbar */
}

.play-button {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  padding: 5px 16px 5px 16px;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s;
}

.add-btn-small {
  font-size: "12px";
  cursor: "pointer";
  padding: 0px;
  margin-bottom: 3px;
  margin-right: 5px
}

.details-page-title {
  padding: 5px;
  position: relative;
  top: 1px;
}

/* Styling for selects to look more like the inputs */
.alignment-select {
  font-size: 1rem; /* Larger font size for clear legibility */
  margin: 0.25rem; /* Consistent spacing around elements */
  padding: 0.5rem; /* Ample padding for a square appearance */
  text-align: center; /* Center text for a neat look */
  border-radius: 5px; /* Rounded corners for inputs and selects */
  border: 1px solid gray; /* Border color to match the theme */
  background-color: #282c34; /* Background color to match the theme */
  color: gray; /* Font color to match the theme */}

/* Styling for when an option is selected in select dropdown */
.alignment-select:valid {
  color: white; /* White text for selected option */
}

/* Fix the layout for alignment selects to be side by side */
.alignment-selects {
  display: flex;
  justify-content: space-between; /* Spacing between the dropdowns */
}