.search-bar-container {
  width: 100%;
  padding: 1rem;
  box-sizing: border-box; /* Ensures padding doesn't add to the width */
}

.search-input {
  width: calc(100% - 2rem); /* Subtract padding */
  max-width: 600px;
  margin: auto; /* Centers the search bar */
}


.search-bar {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 95%;
  margin-top: 0px;
}
/* Additional styles for the SearchBar component */
#searching-txt {
  display: block;
  font-size: 16px;
  padding-top: 1px;
}

#searching-quote {
  font-style: italic;
  color: lightgray;
  font-family: 'Times New Roman';
}

#searching-spinner {
  display: none;
  font-size: 20px;
  color: gray;
}

.search-button {
  margin-left: 10px;
}

.search-clear {
  cursor: pointer;
  color: white;
  margin-left: 10px;
}
