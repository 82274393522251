.episode-detail-overlay {
  position: relative;
  text-align: left;
  color: white;
}

.episode-still-image {
  width: 100%;
  border-radius: 20px;
}

.episode-overview {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5); /* Gray overlay */
  display: flex;
  opacity: .8;
  flex-direction: column; 
  padding: 20px;
}
