.search-results-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}

.search-result-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  background: #1c1e26; /* Adjust the color to match your theme */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease-in-out;
}

.search-result-card:hover {
  transform: translateY(-5px);
}

.search-result-image {
  border-radius: 10px;
}

.search-result-title {
  margin: 1rem;
  font-size: 1rem;
  color: white;
}

.media-item {
  position: relative;
}

.more-like-this {
  display: none;
  position: absolute;
  bottom: 0px;
  right: 44px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-size: small;;
  cursor: pointer;
}

.media-item:hover .more-like-this {
  display: block;
}
