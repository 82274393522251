.cast-details-info {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr)); /* Creates a responsive grid */
    gap: 20px; /* Space between cards */
    padding: 20px;
  }
  
  .character-card {
    background-color: #282c34; /* Dark background for the card */
    color: lightgray; /* Light text color for readability */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 8px;
    padding: 20px;
    font-size: 20px;
    margin-bottom: 20px; /* Margin between cards when they wrap */
  }
  
  .cast-images {
    padding-bottom: 20px; /* Space below the image container */
  }
  
  
  ul {
    list-style: none; /* Removes bullet points */
    padding-left: 0; /* Removes padding */
  }
  
  li {
    margin-bottom: 10px; /* Space between list items */
    padding-left: 20px; /* Padding for list content */
    position: relative;
  }
  
  li:before {
    content: "•"; /* Adds a custom bullet point */
    position: absolute;
    left: 0;
  }
  

  .character-main-card {
    color: lightgray; /* Light text color for readability */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 8px;
    font-size: 20px;
    display: flex;
    justify-content: space-around; /* Layout images and text side by side */
    align-items: top; /* Align items vertically */
    gap: 20px; /* Space between images and text */
  }
  
  .cast-images {
    display: flex;
    gap: 20px; /* Space between profile and media images */
  }
  
  img.cast-profile-image {
    border-radius: 10px; /* Rounded corners for images */
    width: 200px; /* Adjusted width for better visibility */
    height: auto; /* Maintain aspect ratio */
    margin: 5px;
  }
  
  ul {
    list-style: none; /* Removes bullet points */
    padding-left: 0; /* Removes padding */
  }
  
  li {
    margin-bottom: 10px; /* Space between list items */
    padding-left: 20px; /* Padding for list content */
    position: relative;
  }
  
  li:before {
    content: "•"; /* Adds a custom bullet point */
    position: absolute;
    left: 0;
  }
