.toasty {
  position: fixed;
  top: 58px;
  right: 20px;
  z-index: 1000;
  background: gray;
  color: white;
  padding: 10px;
  opacity: .85;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toast-close-btn {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}
